import * as React from 'react';
import * as Immutable from 'immutable';
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import prepareOktaLoadGroupsPayload from 'authentication/logic/okta/prepareOktaLoadGroupsPayload';
import { OktaConfigFormValues, OktaSubmitPayload } from 'authentication/components/okta/config/types';
import { OktaBackendMetaContext } from 'authentication/components/okta/config/components/OktaBackendMetaProvider';

import OktaMatchingGroupsContext from './OktaMatchingGroupsContext';

type Props = {
  children: React.ReactNode,
  prepareSubmitPayload: (values?: OktaConfigFormValues) => OktaSubmitPayload,
};

const OktaMatchingGroupsProvider = ({ children, prepareSubmitPayload }: Props) => {
  const { backendId, backendGroupSyncIsActive } = useContext(OktaBackendMetaContext);
  const { values: currentFormValues } = useFormikContext<OktaConfigFormValues>();
  const [contextValue, setContextValue] = useState({ result: undefined, finishedLoading: true });

  useEffect(() => {
    // When editing a backend with active group sync, load matching groups
    if (backendId && backendGroupSyncIsActive) {
      setContextValue({ finishedLoading: false, result: undefined });
      const payload = prepareOktaLoadGroupsPayload(prepareSubmitPayload, currentFormValues, backendId, 'okta');

      GroupSyncActions.loadGroups(payload).then((result) => {
        setContextValue({ finishedLoading: true, result });
      }).catch((error) => {
        setContextValue({ finishedLoading: true, result: { errors: Immutable.List([error]) } });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OktaMatchingGroupsContext.Provider value={{ ...contextValue, setContextValue }}>
      {children}
    </OktaMatchingGroupsContext.Provider>
  );
};

OktaMatchingGroupsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OktaMatchingGroupsContext };

export default OktaMatchingGroupsProvider;
