import React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import { trim } from 'lodash';
import styled, { css, DefaultTheme } from 'styled-components';

import connect from 'stores/connect';
import { DropdownButton, FormGroup, InputGroup, MenuItem } from 'components/graylog';
import CustomPropTypes from 'views/components/CustomPropTypes';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import ParameterType from 'views/logic/parameters/Parameter';
import TypeSpecificParameterInput from 'enterprise/parameters/components/TypeSpecificParameterInput';
import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledDropdownButton = styled(DropdownButton)(({ theme, $validationState }: { theme: DefaultTheme, $validationState: null | 'error' }) => {
  if ($validationState === 'error') {
    return css`
      && {
        border: 1px solid ${theme.colors.variant.light.danger};
        border-right: 0;
      }
    `;
  }
});

type Props = {
  existingParameters: ParameterMap,
  parameter: ParameterType,
  searchId: string,
  binding?: string,
  onBlur: () => void,
  onChange: (name: string, value: any, submit?: boolean) => void,
  onDelete: (name: string) => any,
  onEdit: (name: string, parameter: ParameterType) => any,
};

type State = {
  binding: string,
};

class Parameter extends React.Component<Props, State> {
  declarationForm: any;

  static propTypes = {
    parameter: CustomPropTypes.instanceOf(ParameterType).isRequired,
    binding: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    binding: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = { binding: props.binding || '' };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { binding: nextBinding } = nextProps;
    const { binding: currentBinding } = this.state;

    if (currentBinding !== nextBinding) {
      this.setState({ binding: nextBinding });
    }
  }

  handleChange = (name: string, value: any, submit: boolean = false): void => {
    const { onChange } = this.props;
    this.setState({ binding: value }, () => onChange(name, value, submit));
  };

  handleEdit = () => {
    this.declarationForm.open();
  };

  handleDelete = () => {
    const { onDelete, parameter } = this.props;
    onDelete(parameter.name);
  };

  handleUpdate = (parameters: ParameterMap) => {
    const { parameter, onEdit } = this.props;
    const newParameter = parameters.get(parameter.name);

    onEdit(parameter.name, newParameter);
    this.declarationForm.close();
  };

  render() {
    const { existingParameters = Immutable.Map(), parameter, onBlur, searchId } = this.props;
    const { binding } = this.state;
    const { name, title } = parameter;
    const validationState = !trim(binding) ? 'error' : null;

    const parameters = Immutable.fromJS({ [name]: parameter });

    const existingParametersWithoutCurrentOne = existingParameters.remove(name);

    return (
      <FormGroup key={name} controlId={`form-inline-${name}`} style={{ marginRight: 5 }} validationState={validationState}>
        <InputGroup>
          <StyledDropdownButton componentClass={InputGroup.Button}
                                id={`parameter-dropdown-${name}`}
                                $validationState={validationState}
                                title={title}>
            <MenuItem key="edit" onSelect={this.handleEdit}>Edit</MenuItem>
            <MenuItem key="delete" onSelect={this.handleDelete}>Delete</MenuItem>
          </StyledDropdownButton>

          <TypeSpecificParameterInput parameter={parameter} value={binding || ''} onChange={this.handleChange} onBlur={onBlur} searchId={searchId} />

          <ParameterDeclarationForm ref={(c) => { this.declarationForm = c; }}
                                    existingParameters={existingParametersWithoutCurrentOne}
                                    parameters={parameters}
                                    onSave={this.handleUpdate} />
        </InputGroup>
      </FormGroup>
    );
  }
}

export default connect(Parameter, { existingParameters: SearchParameterStore });
