import Reflux from 'reflux';

import { qualifyUrl } from 'util/URLUtils';
import AppConfig from 'util/AppConfig';
import { Builder } from 'logic/rest/FetchProvider';

export const OidcConfigurationActions = Reflux.createActions({
  getConfig: { asyncResult: true },
});

export const OidcConfigurationStore = Reflux.createStore({
  listenables: [OidcConfigurationActions],
  sourceUrl: '/plugins/org.graylog.plugins.security/okta/authconfig',
  configuration: undefined,

  init() {
    if (!AppConfig.isCloud()) {
      this.getConfig();
    }
  },

  getState() {
    return { configuration: this.configuration };
  },

  getInitialState() {
    return this.getState();
  },

  propagateState() {
    this.trigger(this.getState());
  },

  getConfig() {
    const builder = new Builder('GET', qualifyUrl(this.sourceUrl))
      .json();

    const promise = builder.build();

    promise.then((response) => {
      this.configuration = response;
      this.propagateState();

      return response;
    });

    OidcConfigurationActions.getConfig.promise(promise);
  },
});
