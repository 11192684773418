import { List } from 'immutable';

import { OktaConfigFormValues, OktaSubmitPayload } from 'authentication/components/okta/config/types';

function payloadFromFormValues(values: OktaConfigFormValues): OktaSubmitPayload {
  return {
    title: values.title,
    description: values.description,
    default_roles: List(values.defaultRoles.split(',')),
    config: {
      type: 'okta',
      okta_base_url: values.oktaBaseUrl,
      callback_url: values.callbackUrl,
      client_id: values.clientId,
      token_verifier_connect_timeout: values.tokenVerifierConnectTimeout || 10,
      client_secret: typeof values.clientSecret === 'undefined' ? { keep_value: true } : { set_value: values.clientSecret },
    },
  };
}

export default payloadFromFormValues;
