import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';

import { Col, Row, Alert } from 'components/graylog';
import { RenderCompletionObserver } from 'components/visualizations';
import withParams from 'routing/withParams';
import { widgetDefinition } from 'views/logic/Widgets';

import style from './ReportRenderPage.css';

import ReportingWidget, { FallbackReportingWidget } from '../common/ReportingWidget';
import ErrorBoundary from '../common/ErrorBoundary';
import ReportsActions from '../ReportsActions';

const WIDGET_ASPECT_RATIO = 3 / 4;
const WIDGET_WIDTH = 600;

class ReportRenderPage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.renderedWidgets = [];

    this.state = {
      renderComplete: false,
    };
  }

  componentDidMount() {
    const { params } = this.props;
    this._loadReport(params.reportId);
  }

  componentWillReceiveProps(nextProps) {
    const { params } = this.props;
    const { reportId } = nextProps.params;

    if (params.reportId !== reportId) {
      this._loadReport(reportId);
    }
  }

  _loadReport = (id) => {
    Promise.all([
      ReportsActions.get(id),
      ReportsActions.getReportLogo(id),
    ]).then(([report, { logo }]) => this.setState({ report, reportLogo: logo }));
  };

  _handleRenderComplete = (widgetId) => {
    const { report } = this.state;

    return () => {
      if (!this.renderedWidgets.includes(widgetId)) {
        this.renderedWidgets = [...this.renderedWidgets, widgetId];
      }

      const { renderComplete } = this.state;
      const isRenderComplete = this._isRenderComplete(report.widgets, this.renderedWidgets);

      if (renderComplete !== isRenderComplete) {
        this.setState({ renderComplete: isRenderComplete });
      }
    };
  };

  _renderParameters = (parameters, parameterValues) => {
    if (isEmpty(parameters)) {
      return null;
    }

    const getParameterValue = (name, defaultValue) => (isNil(parameterValues[name]) ? defaultValue : parameterValues[name]);

    const formattedParameters = Object.entries(parameters)
      .map(([name, { default_value: defaultValue }]) => {
        const value = getParameterValue(name, defaultValue);

        return <tr key={name}><td>${name}$</td><td>{value || <em>Not set</em>}</td></tr>;
      });

    const parametersWithoutValue = Object.values(parameters)
      .filter((({ name, default_value: defaultValue }) => !getParameterValue(name, defaultValue)))
      .map(({ name }) => name);

    return (
      <>
        {parametersWithoutValue.length > 0 && (
          <Alert bsStyle="danger" className={style.parametersWarning}>
            <h3>Attention: Missing parameter value</h3>
            <p>
              This report contains parameters without any value. Widgets which include these parameters will not display a result.
              Please add a default value on the related dashboard or add a value on the report edit page for the following parameters:
            </p>
            <b>{parametersWithoutValue.join(', ')}</b>
          </Alert>
        )}
        <p className={style.description}>This report uses the following parameters and values:</p>
        <table className={style.parameters}>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {formattedParameters}
          </tbody>
        </table>
      </>
    );
  };

  _renderWidgets = (widgets, positions, parameterValues) => {
    return widgets
      .sort((widgetA, widgetB) => {
        if (positions.length === 0) {
          return 0;
        }

        const positionA = positions.find((p) => p.dashboard_widget_id === widgetA.dashboard_widget_id);
        const positionB = positions.find((p) => p.dashboard_widget_id === widgetB.dashboard_widget_id);

        return (positionA && positionB ? positionA.row - positionB.row : 0);
      })
      .map((widget) => {
        const widgetDef = widgetDefinition(widget.type);
        const reportStyle = typeof widgetDef.reportStyle === 'function' ? widgetDef.reportStyle() : { width: WIDGET_WIDTH };
        const widgetWidth = reportStyle.width;
        // Width is going to be limited by the paper size, but we try our best to adjust the visualization height.
        const effectiveHeight = widgetWidth * WIDGET_ASPECT_RATIO;
        const handleRenderComplete = this._handleRenderComplete(widget.dashboard_widget_id);

        return (
          <div key={widget.dashboard_widget_id} className={style.visualization}>
            <RenderCompletionObserver onRenderComplete={handleRenderComplete}>
              <ErrorBoundary FallbackComponent={FallbackReportingWidget}
                             onDidCatch={handleRenderComplete}
                             widget={widget}>
                <ReportingWidget dashboardId={widget.dashboard_id}
                                 widget={widget}
                                 width={widgetWidth}
                                 height={effectiveHeight}
                                 parameterValues={parameterValues}
                                 showHandle={false}
                                 interactive={false} />
              </ErrorBoundary>
            </RenderCompletionObserver>
          </div>
        );
      });
  };

  _isRenderComplete = (widgets, renderedWidgets) => {
    return widgets.length === renderedWidgets.length;
  };

  render() {
    const { renderComplete, report, reportLogo } = this.state;
    const isLoading = !report;

    if (isLoading) {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          <div className={style.reportPage}>
            <div className={style.coverPage}>
              <h1>{report.title}</h1>
              <h2>{report.subtitle}</h2>
              {reportLogo && <div className={style.logo}><img src={reportLogo} alt="report-logo" /></div>}
            </div>
            <div className={style.pageBreak} />
            <p className={style.description}>{report.description}</p>
            {report.parameterValues && this._renderParameters(report.parameters, report.parameterValues)}
            {this._renderWidgets(report.widgets, report.positions, report.parameterValues)}
            {renderComplete && <div id="render-complete" style={{ display: 'none' }} /> }
          </div>
        </Col>
      </Row>
    );
  }
}

export default withParams(ReportRenderPage);
