import * as React from 'react';
import { useRef, useState } from 'react';
import { Overlay } from 'react-overlays';
import styled, { css } from 'styled-components';

import SketchPicker from 'components/common/color-picker/SketchPicker';
import { Button } from 'components/graylog';

type Props = {
  color: string,
  onChange: ({ hex: string }) => void,
  label: string,
}

// define ColorPreview size so we can math it later for positioning SketchPicker
const colorPreviewSize = 21;

const Wrapper = styled.div`
  position: relative;
`;

const ColorPreview = styled.span<{ $color: string }>(({ $color, theme }) => css`
  background-color: ${$color};
  width: ${colorPreviewSize}px;
  height: ${colorPreviewSize}px;
  display: block;
  border: 1px solid ${theme.colors.variant.darker.default};
  border-radius: 2px;
`);

const StyledButton = styled(Button)`
  padding: 0;
  line-height: 1;
`;

const StyledSketchPicker = styled(SketchPicker)`
  position: absolute;
  transform: translateY(calc(-50% - ${colorPreviewSize / 2}px));
  left: calc(50% + ${colorPreviewSize - 3}px);
  z-index: 2;
  
  &::after,
  &::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-right-color: #fff; /* matching color from SketchPicker background-color */
    border-width: 9px;
    margin-top: -9px;
  }

  &::before {
    border-color: transparent;
    border-right-color: rgba(0, 0, 0, 0.15); /* matching color from SketchPicker box-shadow */
    border-width: 10px;
    margin-top: -10px;
  }
`;

const ThemeEditorColor = ({ color, onChange, label }: Props) => {
  const [show, setShow] = useState(false);
  const menuContainer = useRef();

  const _handleToggle = () => {
    setShow(!show);
  };

  return (
    <Wrapper ref={menuContainer}>
      <StyledButton bsStyle="link" onClick={_handleToggle} title={label}>
        <ColorPreview $color={color} />
      </StyledButton>

      <Overlay show={show}
               container={menuContainer.current}
               placement="right"
               shouldUpdatePosition
               rootClose
               onHide={_handleToggle}>
        <div role="dialog">
          <StyledSketchPicker disableAlpha
                              color={color}
                              onChangeComplete={({ hex }) => onChange(hex)} />
        </div>
      </Overlay>
    </Wrapper>
  );
};

export default ThemeEditorColor;
