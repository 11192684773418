import * as React from 'react';

import { singleton } from 'views/logic/singleton';

export type OktaBackendMetaContextType = {
  backendId: string,
  backendGroupSyncIsActive: boolean,
  licenseIsValid: boolean,
  setContextValue: ({ backendId, backendGroupSyncIsActive, licenseIsValid }: { backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean }) => void,
};

export const initialState = {
  backendId: undefined,
  backendGroupSyncIsActive: false,
  licenseIsValid: false,
  setContextValue: () => {},
};

const OktaBackendMetaContext = React.createContext<OktaBackendMetaContextType>(initialState);
export default singleton('contexts.authentication.enterprise.okta.formValues', () => OktaBackendMetaContext);
