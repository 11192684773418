import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/graylog/router';
import Routes from 'routing/Routes';
import Panel from 'components/graylog/Panel';
import Spinner from 'components/common/Spinner';
import { Col, Row } from 'components/graylog';
import AppConfig from 'util/AppConfig';

import { LicensesActions } from './LicensesStore';

type InvalidLicenseWarningProps = {
  featureName: string | undefined | null,
  displayWarningContainer: boolean,
};

const isCloud = AppConfig.isCloud();

export const Warning = ({ featureName, className }: { featureName: string, className?: string }) => (
  <Panel.Body className={className}>
    A valid enterprise license is needed to use {featureName ?? 'this'} functionality.<br />
    {isCloud
      ? (<>Contact your Graylog account manager.</>)
      : (
        <>Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
          more information or contact your Graylog account manager.
        </>
      )}
  </Panel.Body>
);

Warning.defaultProps = {
  className: undefined,
};

const InvalidLicenseWarning = ({ featureName, displayWarningContainer }: InvalidLicenseWarningProps) => {
  if (displayWarningContainer) {
    return (
      <Row className="content">
        <Col xs={12}>
          <Panel bsStyle="warning" className="no-bm">
            <Warning featureName={featureName} />
          </Panel>
        </Col>
      </Row>
    );
  }

  return (<Panel bsStyle="warning"><Warning featureName={featureName} /></Panel>);
};

type Props = {
  children: React.ReactNode | (({ licenseIsValid: boolean }) => React.ReactNode),
  displayLicenseWarning: boolean,
  featureName: string | undefined | null,
  displayWarningContainer: boolean
  displayWarningComponent: React.ReactNode,
  hideChildren: boolean,
};

/**
 * Component which provides a `licenseIsValid` prop. E.g. to display a read only version of its children.
 */
const LicenseCheck = ({ children, featureName, displayLicenseWarning, displayWarningContainer, hideChildren, displayWarningComponent }: Props) => {
  const [licenseIsValid, setLicenseIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const resultChildren = typeof children === 'function' ? children({ licenseIsValid }) : children;
  const Component = displayWarningComponent || <InvalidLicenseWarning featureName={featureName} displayWarningContainer={displayWarningContainer} />;

  useEffect(() => {
    LicensesActions.listStatus().then((response) => {
      const status = response?.status ?? [];
      const valid = status.reduce((result, state) => result || state.valid, false);
      setLicenseIsValid(valid);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {(!licenseIsValid && displayLicenseWarning) && Component}
      {licenseIsValid && resultChildren}
      {!licenseIsValid && !hideChildren && resultChildren}
    </>
  );
};

LicenseCheck.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  displayLicenseWarning: PropTypes.bool,
  hideChildren: PropTypes.bool,
  featureName: PropTypes.string,
  displayWarningComponent: PropTypes.element,
  displayWarningContainer: PropTypes.bool,
};

LicenseCheck.defaultProps = {
  displayLicenseWarning: true,
  featureName: undefined,
  displayWarningContainer: false,
  displayWarningComponent: undefined,
  hideChildren: false,
};

export default LicenseCheck;
