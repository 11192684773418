import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/Icon';
import { OverlayTrigger, Tooltip } from 'components/graylog';

interface Props {
  message: string,
}

const NotificationHelp = ({ message }: Props) => {
  return (
    <OverlayTrigger placement="top"
                    overlay={<Tooltip id="tooltip-help-message" className="in">{message}</Tooltip>}
                    trigger={['hover', 'click']}
                    rootClose>
      <span>
        <Icon name="question-circle" />
      </span>
    </OverlayTrigger>
  );
};

NotificationHelp.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotificationHelp;
