/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';

import { DocumentTitle } from 'components/common';
import WizardPageHeader from 'components/authentication/directoryServices/ldap/WizardPageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import history from 'util/History';
import Routes from 'routing/Routes';
import OktaBackendMetaProvider from 'authentication/components/okta/config/components/OktaBackendMetaProvider';
import LicenseCheck from 'license/LicenseCheck';

import { OktaConfigFormValues } from './types';
import { handleCreate } from './helpers/handleSubmit';
import payloadFromFormValues from './helpers/payloadFromFormValues';
import BackendWizard from './BackendWizard';

const BackendCreate = () => {
  const onSubmitForm = (values: OktaConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync?: boolean) => {
    return handleCreate(payloadFromFormValues(values), values, shouldUpdateGroupSync)
      .then(() => {
        history.push(Routes.SYSTEM.AUTHENTICATION.BACKENDS.OVERVIEW);
      }).catch((error) => {
        throw error;
      });
  };

  return (
    <DocumentTitle title="Create Okta Authentication Service">
      <WizardPageHeader />
      <SectionComponent title="Create Okta Authentication Service">
        <LicenseCheck>
          {({ licenseIsValid }) => {
            return (
              <OktaBackendMetaProvider defaultValue={{ backendId: undefined, backendGroupSyncIsActive: false, licenseIsValid }}>
                <BackendWizard onSubmitForm={onSubmitForm} groupSyncValues={{}} />
              </OktaBackendMetaProvider>
            );
          }}
        </LicenseCheck>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default BackendCreate;
