import * as React from 'react';

import { ParameterDeclarationComponentProps, useParameterType } from './ParameterTypes';

type Props = ParameterDeclarationComponentProps & {
  type: string,
};

const TypeSpecificDeclarationFields = ({ idx, type, parameter, onChange, validationState, onValidate }: Props) => {
  const { editComponent: Component } = useParameterType(type);

  return <Component idx={idx} parameter={parameter} onChange={onChange} validationState={validationState} onValidate={onValidate} />;
};

export default TypeSpecificDeclarationFields;
