/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import * as Immutable from 'immutable';

import { DocumentTitle, Spinner } from 'components/common';
import WizardPageHeader from 'components/authentication/directoryServices/ldap/WizardPageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import { OktaBackend } from 'logic/authentication/okta/types';
import history from 'util/History';
import Routes from 'routing/Routes';
import useOktaInitialGroupSyncValues from 'authentication/components/okta/useOktaInitialGroupSyncValues';
import OktaBackendMetaProvider from 'authentication/components/okta/config/components/OktaBackendMetaProvider';
import LicenseCheck from 'license/LicenseCheck';

import { OktaConfigFormValues } from './types';
import { handleUpdate } from './helpers/handleSubmit';
import payloadFromFormValues from './helpers/payloadFromFormValues';
import BackendWizard from './BackendWizard';

interface BackendEditProps {
  authenticationBackend: OktaBackend;
}

const BackendEdit: React.FunctionComponent<BackendEditProps> = ({ authenticationBackend }: BackendEditProps) => {
  const {
    formValues: groupFormValues,
    finishedLoading,
  } = useOktaInitialGroupSyncValues(authenticationBackend.id, { teamSelection: Immutable.Set<string>() });

  if (authenticationBackend?.id && !finishedLoading) {
    return <Spinner />;
  }

  const onSubmitForm = (values: OktaConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync?: boolean) => {
    return handleUpdate(payloadFromFormValues(values), authenticationBackend.id, values, backendGroupSyncIsActive, shouldUpdateGroupSync)
      .then(() => {
        history.push(Routes.SYSTEM.AUTHENTICATION.BACKENDS.OVERVIEW);
      }).catch((error) => {
        throw error;
      });
  };

  return (
    <DocumentTitle title="Edit Okta Authentication Service">
      <WizardPageHeader authenticationBackend={authenticationBackend} />
      <SectionComponent title="Edit Okta Authentication Service">
        <LicenseCheck>
          {({ licenseIsValid }) => {
            return (
              <OktaBackendMetaProvider defaultValue={{ backendId: authenticationBackend?.id, backendGroupSyncIsActive: !!groupFormValues?.synchronizeGroups, licenseIsValid }}>
                <BackendWizard authenticationBackend={authenticationBackend} onSubmitForm={onSubmitForm} groupSyncValues={groupFormValues} />
              </OktaBackendMetaProvider>
            );
          }}
        </LicenseCheck>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default BackendEdit;
