import { OktaBackendConfig, OktaBackendConfigJson } from 'logic/authentication/okta/types';

export function fromJson(input: OktaBackendConfigJson): OktaBackendConfig {
  return {
    type: input.type,
    oktaBaseUrl: input.okta_base_url,
    clientId: input.client_id,
    clientSecret: input.client_secret,
    tokenVerifierConnectTimeout: input.token_verifier_connect_timeout,
    callbackUrl: input.callback_url,
  };
}

export function toJson(input: OktaBackendConfig): OktaBackendConfigJson {
  return {
    type: input.type,
    okta_base_url: input.oktaBaseUrl,
    client_id: input.clientId,
    client_secret: input.clientSecret,
    token_verifier_connect_timeout: input.tokenVerifierConnectTimeout,
    callback_url: input.callbackUrl,
  };
}
