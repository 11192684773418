import Reflux from 'reflux';

const ReportsActions = Reflux.createActions({
  list: { asyncResult: true },
  listPage: { asyncResult: true },
  create: { asyncResult: true },
  get: { asyncResult: true },
  getReportLogo: { asyncResult: true },
  update: { asyncResult: true },
  updateScheduling: { asyncResult: true },
  updatePositions: { asyncResult: true },
  delete: { asyncResult: true },
  sendEmail: { asyncResult: true },
  getHistory: { asyncResult: true },
});

export default ReportsActions;
