import * as React from 'react';
import { FunctionComponent } from 'react';

import Routes from 'routing/Routes';
import { Button } from 'components/graylog';
import { LinkContainer } from 'components/graylog/router';
import { ReadOnlyFormGroup, Spinner } from 'components/common';
import SectionComponent from 'components/common/Section/SectionComponent';
import useRoles from 'authentication/components/okta/config/hooks/useRoles';
import { OktaBackend } from 'logic/authentication/okta/types';

import convertToSeconds from '../helpers/convertToSeconds';

interface ConfigSectionProps {
  authenticationBackend: OktaBackend;
}

const ConfigSection: FunctionComponent<ConfigSectionProps> = ({ authenticationBackend }: ConfigSectionProps) => {
  const { id, title, description, defaultRoles, config } = authenticationBackend;
  const { oktaBaseUrl, callbackUrl, clientId, clientSecret, tokenVerifierConnectTimeout } = config;
  const { isLoading, roles } = useRoles();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <SectionComponent title="Server Configuration"
                      headerActions={(
                        <LinkContainer to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.edit(id, 'server_config')}>
                          <Button bsStyle="success" bsSize="small">Edit</Button>
                        </LinkContainer>
                      )}>
      <ReadOnlyFormGroup label="Title" value={title} />
      <ReadOnlyFormGroup label="Description" value={description} />
      <ReadOnlyFormGroup label="Okta base URL" value={oktaBaseUrl} />
      <ReadOnlyFormGroup label="Callback URL" value={callbackUrl} />
      <ReadOnlyFormGroup label="Client ID" value={clientId} />
      <ReadOnlyFormGroup label="Client Secret" value={clientSecret?.is_set ? '******' : null} />
      <ReadOnlyFormGroup label="Token verifier connect timeout" value={convertToSeconds(tokenVerifierConnectTimeout)} />
      <ReadOnlyFormGroup label="Default roles" value={defaultRoles.map((roleId) => roles.find((role) => role.id === roleId)?.name).join(', ')} />
    </SectionComponent>
  );
};

export default ConfigSection;
