// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3On9RtdYYtp5JzqGYKTRN4 {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n\n._3y5nV8l78Bu82IXDf_1A3_ {\n    margin-bottom: 10px;\n    list-style: none;\n}\n\n._2o7sa25NDP3EZ4_BkpLGaY {\n    margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/components/Value.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[":local(.dropdownheader) {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n\n:local(.bottomSpacer) {\n    margin-bottom: 10px;\n    list-style: none;\n}\n\n:local(.topSpacer) {\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownheader": "_3On9RtdYYtp5JzqGYKTRN4",
	"bottomSpacer": "_3y5nV8l78Bu82IXDf_1A3_",
	"topSpacer": "_2o7sa25NDP3EZ4_BkpLGaY"
};
export default ___CSS_LOADER_EXPORT___;
