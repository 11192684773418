import * as React from 'react';
import * as Immutable from 'immutable';
import { FunctionComponent, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { Formik } from 'formik';
import { camelCase, mapKeys, mapValues } from 'lodash';

import { OktaBackend, OktaTeamSyncConfig } from 'logic/authentication/okta/types';
import Wizard from 'components/common/Wizard';
import { Spinner } from 'components/common';
import OktaMatchingGroupsProvider from 'authentication/components/okta/config/components/OktaMatchingGroupsProvider';
import payloadFromFormValues from 'authentication/components/okta/config/helpers/payloadFromFormValues';
import { OktaBackendMetaContext } from 'authentication/components/okta/config/components/OktaBackendMetaProvider';
import { Row, Col, Alert } from 'components/graylog';
import FetchError from 'logic/errors/FetchError';
import convertToSeconds from 'authentication/components/okta/config/helpers/convertToSeconds';

import ServerConfigurationForm from './components/ServerConfigurationForm';
import GroupSyncForm from './components/GroupSyncForm';
import { OktaConfigFormValues } from './types';
import useRoles from './hooks/useRoles';

const defaultValues: OktaBackend = {
  id: undefined,
  title: '',
  description: '',
  defaultRoles: Immutable.List(),
  config: {
    type: 'okta',
    oktaBaseUrl: '',
    callbackUrl: '',
    clientId: '',
    clientSecret: { is_set: false },
    tokenVerifierConnectTimeout: '',
  },
};
const SubmitAllError = ({ error, backendId }: { error: FetchError, backendId: string | null | undefined }) => (
  <Row>
    <Col xs={9} xsOffset={3}>
      <Alert bsStyle="danger" style={{ wordBreak: 'break-word' }}>
        <b>Failed to {backendId ? 'edit' : 'create'} authentication service</b><br />
        {error?.message && <>{error.message}<br /><br /></>}
        {error?.additional?.res?.text}
      </Alert>
    </Col>
  </Row>
);

const _formatBackendValidationErrors = (backendErrors: { [inputNameJSON: string]: string[] }) => {
  const backendErrorStrings = mapValues(backendErrors, (errorArray) => `Server validation error: ${errorArray.join(' ')}`);

  return mapKeys(backendErrorStrings, (value, key) => camelCase(key));
};

interface BackendWizardProps {
  onSubmitForm: (values: OktaConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync?: boolean) => Promise<any>;
  authenticationBackend?: OktaBackend;
  groupSyncValues?: OktaTeamSyncConfig;
}

const BackendWizard: FunctionComponent<BackendWizardProps> = ({
  authenticationBackend,
  onSubmitForm,
  groupSyncValues,
}: BackendWizardProps) => {
  const location = useLocation();
  const searchParams = parse(location.search.substr(1));
  const [wizardStep, setWizardStep] = useState<'server_config' | 'group_sync'>(searchParams.initialStepKey || 'server_config');
  const [submitErrors, setSubmitErrors] = useState(undefined);

  const { backendGroupSyncIsActive, licenseIsValid, backendId } = useContext(OktaBackendMetaContext);
  const { title, description, config, defaultRoles } = authenticationBackend;
  const { oktaBaseUrl, callbackUrl, clientId, clientSecret, tokenVerifierConnectTimeout } = config;
  const { isLoading, roles } = useRoles();

  if (isLoading) {
    return <Spinner />;
  }

  const defaultCreateRoleId = roles.find((role) => role.name === 'Reader')?.id;
  const defaultRolesValues = defaultRoles.toArray().join(',');
  const hasSecret = clientSecret.is_set;
  let hasApiToken;
  let initialValues = {
    title,
    description,
    oktaBaseUrl,
    callbackUrl,
    clientId,
    clientSecret: hasSecret ? undefined : '',
    tokenVerifierConnectTimeout: convertToSeconds(tokenVerifierConnectTimeout) || 10,
    defaultRoles: defaultRolesValues === '' ? defaultCreateRoleId : defaultRolesValues,
    teamDefaultRoles: undefined,
    teamSelectionType: undefined,
    teamSelection: Immutable.Set<string>(),
    synchronizeGroups: undefined,
    oktaApiToken: undefined,
  };

  if (groupSyncValues) {
    const { oktaApiToken, ...rest } = groupSyncValues;
    hasApiToken = oktaApiToken?.is_set;
    initialValues = { ...initialValues, oktaApiToken: hasApiToken ? undefined : '', ...rest };
  }

  const _goToStep = (step: 'server_config' | 'group_sync') => setWizardStep(step);

  const _handleSubmit = (values: OktaConfigFormValues, { setErrors, setSubmitting }) => {
    const _submit = () => {
      onSubmitForm(values, backendGroupSyncIsActive, licenseIsValid).catch((error) => {
        if (typeof error?.additional?.body?.errors === 'object') {
          const backendValidationErrors = _formatBackendValidationErrors(error.additional.body.errors);
          setErrors(backendValidationErrors);
        } else {
          setSubmitErrors(error);
        }
      }).finally(() => {
        setSubmitting(false);
      });
    };

    if (backendGroupSyncIsActive && !values.synchronizeGroups) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to remove the group synchronization config for this authentication service?')) {
        _submit();
      }

      setSubmitting(false);

      return;
    }

    _submit();
  };

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            onSubmit={_handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}>
      {({ isSubmitting, values, setFieldValue, handleSubmit, validateForm }) => {
        return (
          <OktaMatchingGroupsProvider prepareSubmitPayload={payloadFromFormValues}>
            <Wizard hidePreviousNextButtons
                    justified
                    horizontal
                    containerClassName=""
                    onStepChange={setWizardStep}
                    activeStep={wizardStep}
                    steps={[
                      {
                        key: 'server_config',
                        title: <span>Server Configuration</span>,
                        component: (
                          <ServerConfigurationForm values={values}
                                                   roles={roles}
                                                   authenticationBackend={authenticationBackend}
                                                   isSubmitting={isSubmitting}
                                                   hasSecret={hasSecret}
                                                   setFieldValue={setFieldValue}
                                                   validateForm={validateForm}
                                                   submitAllError={submitErrors && <SubmitAllError error={submitErrors} backendId={backendId} />}
                                                   goToNext={() => _goToStep('group_sync')} />
                        ),
                      },
                      {
                        key: 'group_sync',
                        title: <span>Group Synchronization <small>(Optional)</small></span>,
                        component: (
                          <GroupSyncForm values={values}
                                         setFieldValue={setFieldValue}
                                         isSubmitting={isSubmitting}
                                         hasApiToken={hasApiToken}
                                         handleSubmit={handleSubmit}
                                         validateForm={validateForm}
                                         submitAllError={submitErrors && <SubmitAllError error={submitErrors} backendId={backendId} />}
                                         goToPrevious={() => _goToStep('server_config')} />
                        ),
                      },
                    ]} />
          </OktaMatchingGroupsProvider>
        );
      }}
    </Formik>
  );
};

BackendWizard.defaultProps = {
  authenticationBackend: defaultValues,
  groupSyncValues: {},
};

export default BackendWizard;
