import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OktaBackendMetaContext from 'authentication/components/okta/config/components/OktaBackendMetaContext';

type Props = {
  children: React.ReactNode,
  defaultValue: { backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean},
};

const OktaBackendMetaProvider = ({ children, defaultValue }: Props) => {
  const [contextValue, setContextValue] = useState<{ backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean } | undefined>(defaultValue);

  return (
    <OktaBackendMetaContext.Provider value={{ ...contextValue, setContextValue }}>
      {children}
    </OktaBackendMetaContext.Provider>
  );
};

OktaBackendMetaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.shape({
    backendId: PropTypes.string,
    backendGroupSyncIsActive: PropTypes.bool,
    licenseIsValid: PropTypes.bool,
  }).isRequired,
};

export { OktaBackendMetaContext };
export default OktaBackendMetaProvider;
