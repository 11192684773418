import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { FormikFormGroup, Icon } from 'components/common';
import { Button, Table, Popover, OverlayTrigger } from 'components/graylog';
import { Backend } from 'archive/types';
import { FILESYSTEM_TYPE } from 'archive/ArchiveStore';

type Props = {
  checkOutputPath: (path: string) => Promise<any>,
};

const StyledPopover = styled(Popover)`
  max-width: 500px;
`;
const StyledDiv = styled.div`
  margin: 10px 0;
`;
const popover = (
  <StyledPopover id="output-base-path-help" className="popover-wide" title="Output Base Path Help">
    <p>You can use variables in the output path to construct a dynamic value for each archive.</p>
    <p><strong>Available variables</strong></p>
    <Table condensed>
      <thead>
        <tr>
          <th>Variable</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>index-name</td>
          <td>Name of the index that gets archived</td>
        </tr>
        <tr>
          <td>year</td>
          <td>Archival date year</td>
        </tr>
        <tr>
          <td>month</td>
          <td>Archival date month</td>
        </tr>
        <tr>
          <td>day</td>
          <td>Archival date day</td>
        </tr>
        <tr>
          <td>hour</td>
          <td>Archival date hour</td>
        </tr>
        <tr>
          <td>minute</td>
          <td>Archival date minute</td>
        </tr>
        <tr>
          <td>second</td>
          <td>Archival date second</td>
        </tr>
      </tbody>
    </Table>
    <p><strong>Example</strong></p>
    {/* eslint-disable-next-line no-template-curly-in-string */}
    <p><code>{'/data/archives/graylog/${year}/${month}'}</code></p>
  </StyledPopover>
);
const outputPathHelp = (
  <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
    <Button bsStyle="link" className="archive-backend-config-output-path-help">
      <Icon name="question-circle" fixedWidth />
    </Button>
  </OverlayTrigger>
);

const ArchiveBackendOutputPathFieldGroup = ({ checkOutputPath }: Props) => {
  const { values: formValues, setFieldError } = useFormikContext<Backend>();
  const [pathCheck, setPathCheck] = useState<string | null>(null);
  const [pathError, setPathError] = useState<string | null>(null);
  const _validateOutputPath = useCallback((path: string) => {
    checkOutputPath(path).then((response) => {
      setPathCheck(response.result);
      setPathError(null);
      setFieldError('settings.output_path', undefined);
    }).catch((error) => {
      try {
        setPathCheck(null);
        const errorMessage = error.additional.body.message;
        setPathError(errorMessage);

        setFieldError('settings.output_path', errorMessage);
      } catch (e) {
        setPathCheck(null);
        setPathError(error.message);

        setFieldError('settings.output_path', error.message);
      }
    });
  }, [checkOutputPath, setFieldError]);
  const _debouncedValidateOutputPath = debounce(_validateOutputPath, 500);
  const label = formValues.settings.type === FILESYSTEM_TYPE ? <span>Output base path {outputPathHelp}</span> : <span> S3 Output base path  {outputPathHelp}</span>;
  const helpText = formValues.settings.type === FILESYSTEM_TYPE ? <>Base path where the archives should be stored.</> : <>Base path where the archives should be stored within the S3 bucket.</>;

  const getPathError = () => {
    return pathError && <span><strong>ERROR:</strong> {pathError}</span>;
  };

  const _onChange = (event) => {
    _debouncedValidateOutputPath(event.target.value);
  };

  useEffect(() => {
    if (formValues?.id) {
      _validateOutputPath(formValues.settings.output_path);
    }
  }, [formValues, _validateOutputPath]);

  return (
    <StyledDiv>
      <FormikFormGroup name="settings.output_path"
                       label={label}
                       required
                       help={<span>{helpText} Can <strong>only</strong> be set on backend creation and not be changed later!</span>}
                       labelClassName=""
                       error={getPathError()}
                       wrapperClassName=""
                       onChange={_onChange}
                       disabled={!!formValues?.id} />
      <span>
        <strong>{formValues.settings.type === FILESYSTEM_TYPE ? 'Example output path: ' : 'S3 Output path preview: '}</strong>
        <code>{pathCheck}</code>
      </span>
    </StyledDiv>
  );
};

ArchiveBackendOutputPathFieldGroup.propTypes = {
  checkOutputPath: PropTypes.func.isRequired,
};

export default ArchiveBackendOutputPathFieldGroup;
