// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._38RTJQllW6EuI53oAC4Z8n {\n    float: right;\n    margin-top: 10px;\n}\n\n._3vC1Rwt3auK9ml7qia2lWb {\n    float: right;\n    margin-top: 10px;\n}\n\n._2BSlH7AfxDEJr0a5nSkYEH .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n._2BSlH7AfxDEJr0a5nSkYEH:not(.ZaiuG9YX-5sXLrEIxEgc3) .description {\n    margin-left: 20px;\n}\n\n._2BSlH7AfxDEJr0a5nSkYEH .form-group {\n    margin-bottom: 0;\n}\n\n.ZaiuG9YX-5sXLrEIxEgc3 {\n    margin-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/common/TableList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe,EAAE,0BAA0B;IAC3C,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[":local(.headerComponentsWrapper) {\n    float: right;\n    margin-top: 10px;\n}\n\n:local(.itemActionsWrapper) {\n    float: right;\n    margin-top: 10px;\n}\n\n:local(.itemWrapper) .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n:local(.itemWrapper):not(:local(.itemWrapperStatic)) .description {\n    margin-left: 20px;\n}\n\n:local(.itemWrapper) .form-group {\n    margin-bottom: 0;\n}\n\n:local(.itemWrapperStatic) {\n    margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerComponentsWrapper": "_38RTJQllW6EuI53oAC4Z8n",
	"itemActionsWrapper": "_3vC1Rwt3auK9ml7qia2lWb",
	"itemWrapper": "_2BSlH7AfxDEJr0a5nSkYEH",
	"itemWrapperStatic": "ZaiuG9YX-5sXLrEIxEgc3"
};
export default ___CSS_LOADER_EXPORT___;
